<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      经营范围： 信息咨询服务（不含许可类信息咨询服务）；信息技术咨询服务；商务代理代办服务；个人信用修复服务；企业信用修复服务；<br>
      互联网数据服务；信息系统集成服务；人工智能应用软件开发；人工智能公共服务平台技术咨询服务；科技中介服务；大数据服务；<br>
      市场营销策划；计算机系统服务（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）。
    </p>
    <div class="bottom">Copyright © 2022 杭州银润信息咨询有限公司 <a href="https://beian.miit.gov.cn/">浙ICP备2024074471号-1</a></div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.bottom {
  bottom: 20px;
  position: absolute;
  text-align: center;
  width: 100%;
}
</style>
